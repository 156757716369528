import React from "react";
import './preloader.css'
import { LeapFrog } from '@uiball/loaders'





function Preloader() {
    return (
        <div className="preloader">
            <LeapFrog
                size={60}
                speed={2.3}
                color="#24a186"
            />
        </div>
    )
}

export default Preloader