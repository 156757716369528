import React, { useEffect } from "react";
import "./table-price-handler.css"


function TablePriceHandler(props) {

    function addSpacesBetweenNumbers(num) {
        if (Number(num)) {
            return new Intl.NumberFormat('ru-RU').format(num)
        } if (num == "#") {
            return ""
        } if (num == "-") {
            return "-"
        } else {
            return "Error"
        }
    }

    function priceColor(opt, line) {
        const mainPrice = document.querySelector(opt)
        const priceLine = document.querySelectorAll(line)

        function decodeHtml(htmlString) {
            return new DOMParser().parseFromString(htmlString.innerHTML, "text/html").body.textContent.replace(/[^0-9]/g, "");
        }

        let optPriceRedCount = 0

        function checkOptPrice(item) {
            let num = Number(decodeHtml(item))
            let decodedMainPrice = mainPrice.innerHTML.replace('&nbsp;', '').replace(',', '.');
            if (num !== 0) {
                if (Number(decodedMainPrice) > num) {
                    item.classList.add("red");
                    optPriceRedCount++
                } else {
                    item.classList.add("green");
                }
            }
        }


        priceLine.forEach((item, id) => {
            item.classList.remove("red");
            item.classList.remove("green");
            checkOptPrice(item)
        })


        if (optPriceRedCount > 0) {
            mainPrice.classList.add("red")

        } else {
            mainPrice.classList.add("green")
        }
    }


    useEffect(() => {
        priceColor(`.main-price-${props.id + 1} a`, `.price__line-${props.id + 1} a`)
    }, [props.sberBankData]);


    return (
        <tr>
            <th className="th-title">
                <p>
                    {props.item.title}
                </p>
            </th>
            <td className="main-price-image__td main-price__border-left">
                <img className="main-price-image" src={"https://keddo.ru/" + props.item.keddo.img} alt="" />
            </td>
            <td className={`main-price-${props.id + 1} main-price__border-right`}>
                <a target="_blank" href={props.item.keddo.link}>{addSpacesBetweenNumbers(props.item.keddo.price)}</a>
            </td>
            <td className={`price__line-${props.id + 1}`}>
                <a target="_blank" href={props.item.bebakids.link}>{addSpacesBetweenNumbers(props.item.bebakids.price)}</a>
            </td>
            <td className={`price__line-${props.id + 1}`}>
                <a target="_blank" href={props.item.parallel.link}>{addSpacesBetweenNumbers(props.item.parallel.price)}</a>
            </td>
            <td className={`price__line-${props.id + 1}`}>
                <a target="_blank" href={props.item.schoolseason.link}>{addSpacesBetweenNumbers(props.item.schoolseason.price)}</a>
            </td>
            <td className={`price__line-${props.id + 1}`}>
                <a target="_blank" href={props.item.strekozza.link}>{addSpacesBetweenNumbers(props.item.strekozza.price)}</a>
            </td>
            <td className={`price__line-${props.id + 1}`}>
                <a target="_blank" href={props.item.rendez_vous.link}>{addSpacesBetweenNumbers(props.item.rendez_vous.price)}</a>
            </td>
            <td className={`price__line-${props.id + 1}`}>
                <a target="_blank" href={props.item.gulliver.link}>{addSpacesBetweenNumbers(props.item.gulliver.price)}</a>
            </td>
            <td className={`price__line-${props.id + 1}`}>
                <a target="_blank" href={props.item.kapika.link}>{addSpacesBetweenNumbers(props.item.kapika.price)}</a>
            </td>
            <td className={`price__line-${props.id + 1}`}>
                <a target="_blank" href={props.item.playtoday.link}>{addSpacesBetweenNumbers(props.item.playtoday.price)}</a>
            </td>
        </tr >
    )
}


export default TablePriceHandler