import React, { useEffect, useRef, useState } from "react";
import './home-page.css'
import SellTable from "../../components/sell-table/sell-table";
import Preloader from "../../components/preloader/preloader";
import axios from "axios"
import Header from "../../components/header/header";

function HomePage() {

    const [data, setData] = useState();
    const [scrollValue, setScrollValue] = useState(0);
    const contentToScrollRef = useRef(null);

    const handleScrollChange = (e) => {
        const newScrollValue = e.target.value;
        setScrollValue(newScrollValue);

        if (contentToScrollRef.current) {
            const maxScroll = contentToScrollRef.current.scrollWidth - contentToScrollRef.current.clientWidth;
            const scrollTo = (maxScroll * newScrollValue) / 100;
            contentToScrollRef.current.scrollLeft = scrollTo;
        }
    };


    useEffect(() => {
        axios.get(
            "https://westex.uz/back/App/form.php"
        ).then((resp) => {
            const data = resp.data;
            setData(data);
        })

        // setData(testData)
    }, []);

    if (data === undefined) {
        return (<Preloader />)
    } if (data === null) {
        return (<Preloader />)
    }

    return (
        <>
            <Header data={data} scrollValue={scrollValue} handleScrollChange={handleScrollChange} />
            <main className="main">
                <div className="table-wrapper" ref={contentToScrollRef}>
                    <SellTable data={data} />
                </div>
            </main>
        </ >
    )
}

export default HomePage