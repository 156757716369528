import React from "react";
import './sell-table.css'
import TablePriceHandler from "../table-price-handler/table-price-handler";


function SellTable(props) {

    return (
        <table className="table">
            <thead>
                <tr>
                    <th className="table-title">
                        Продажа
                    </th>
                    <th colspan="2" className="table-title main-price__border-left main-price__border-right">
                        <a href="https://keddo.ru/" target="_blank">Keddo</a>
                    </th>
                    <th className="table-title">
                        <a href="https://www.bebakids.ru/" target="_blank">Bebakids</a>
                    </th>
                    <th className="table-title">
                        <a href="https://parallel66.ru/" target="_blank">Parallel</a>
                    </th>
                    <th className="table-title">
                        <a href="https://schoolseason.ru/"
                            target="_blank">Schoolseason</a>
                    </th>
                    <th className="table-title">
                        <a href="https://strekozza.ru/"
                            target="_blank">Strekozza</a>
                    </th>
                    <th className="table-title">
                        <a href="https://www.rendez-vous.ru/"
                            target="_blank">Rendez vous</a>
                    </th>
                    <th className="table-title">
                        <a href=""
                            target="_blank">Gulliver</a>
                    </th>
                    <th className="table-title">
                        <a href="https://kapika.ru/"
                            target="_blank">Kapika</a>
                    </th>
                    <th className="table-title">
                        <a href="https://playtoday.ru/"
                            target="_blank">Playtoday</a>
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    props.data.catalog.map((item, id) => (
                        <TablePriceHandler key={id} id={id} item={item} data={props.data} />
                    ))
                }
            </tbody>
        </table >
    )
}

export default SellTable