import { Route, Routes } from 'react-router-dom';
import './App.css';
import Authorization from './pages/authorization/authorization';


function App() {

  return (
    <Routes>
      <Route exact path='/' element={<Authorization />} />
      <Route path='*' element={<Authorization />} />
    </Routes>
  );
}

export default App;
