import React from "react";
import './header.css'


function Header(props) {

    return (
        <header className="header">
            <p className="update-date">Время последнего обновления в <span>{props.data.date}</span></p>
            {/* <input className="range" type="range" min="0" max="100" step="1" value={props.scrollValue} onChange={props.handleScrollChange} /> */}
        </header>
    )
}

export default Header