import React, { useState } from "react";
import './authorization.css'
import LoginForm from "../../components/login-form/login-form";
import HomePage from "../home-page/home-page";

function Authorization() {

    const [authorization, setAuthorization] = useState(false)

    return (
        <>
            {
                authorization ? <HomePage /> : <LoginForm setAuthorization={setAuthorization} authorization={authorization} />
            }
        </>
    )
}

export default Authorization
