import React, { useEffect, useState } from "react";
import './login-form.css'

function LoginForm(props) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const login = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://westex.uz/back/App/login.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();

            if (data.success) {
                setTokenInCookie(data.token);
                localStorage.setItem('authToken', data.token);
                checkTokenOnInitialization();
            } else {
                console.log("Неверный логин или пароль");
            }
        } catch (error) {
            console.error("Ошибка при выполнении запроса:", error);
        }
    };



    function setTokenInCookie(token) {
        // document.cookie = `authToken=${token}; path=/; max-age=3600`; // Пример установки на 1 час
        document.cookie = `authToken=${token}; path=/; max-age=120`;
    }

    function getTokenFromCookie() {
        const cookieString = document.cookie;
        const cookieArray = cookieString.split('; ');
        for (const cookie of cookieArray) {
            const [name, value] = cookie.split('=');
            if (name === 'authToken') {
                return value;
            }
        }
        return null;
    }

    function checkTokenOnInitialization() {
        const cookieToken = localStorage.getItem('authToken')
        const localStorageToken = getTokenFromCookie();

        if (cookieToken !== null) {
            if (cookieToken == localStorageToken) {
                props.setAuthorization(true)
            }
        }
    }
    useEffect(() => {
        checkTokenOnInitialization()
    }, []);

    return (
        <div className="login-wrapper">
            <form onSubmit={login} method="post" className="login-form form-floating mb-3">
                <div className="form-floating mb-3">
                    <input type="text" className="login-form-input form-control" id="floatingInput" placeholder="User" onChange={(e) => setUsername(e.target.value)} />
                    <label htmlFor="floatingInput">Логин</label>
                </div>
                <div className="form-floating">
                    <input type="password" className="login-form-input form-control" id="floatingPassword" placeholder="123" onChange={(e) => setPassword(e.target.value)} />
                    <label htmlFor="floatingPassword">Пароль</label>
                </div>
                <button className="login-form-btn btn btn-primary">Войти</button>
            </form>
        </div>
    )
}


export default LoginForm